<template>
  <div class="d-flex pa-2">
    <div class="">
      <v-avatar >
        <v-icon size="45" color="primary">
          {{item.icon}}
        </v-icon>
      </v-avatar>
    </div>
    <div class="ml-5 black--text body-1 font-weight-bold">
      {{item.title}}
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>