<template>
  <section>
    <div class="ma-10">
      <div class="display-1 primary--text font-weight-bold">General Settings</div>
      <div class="d-flex flex-wrap my-10" v-if="$route.name == 'Admin General Settings'">
        <v-card 
        v-for="(item, i) in items" :key="i"
        outlined 
        width="300"
        class="ma-3">
          <v-card-text >
            <router-link :to="{name: item.to }" class="unlink">
              <Card :item="item"/>
            </router-link>
          </v-card-text>
        </v-card>
      </div>
      <router-view />
    </div>
  </section>
</template>

<script>
import Card from '../components/setting/UserCard.vue'

export default {
  components: {
    Card
  },
  data: () => ({
    items: [
      {
        title: "USER CATEGORIZE",
        to: "Admin User Category",
        icon: "mdi-account-multiple-outline",
      },
      // {
      //   title: "",
      //   to: "Admin Student Categorize",
      //   icon: "mdi-account-arrow-up-outline",
      // },
      // {
      //   title: "",
      //   to: "Admin Student Categorize",
      //   icon: "mdi-account-arrow-up-outline",
      // },
      // {
      //   title: "",
      //   to: "Admin Student Categorize",
      //   icon: "mdi-account-arrow-up-outline",
      // },
      // {
      //   title: "",
      //   to: "Admin Student Categorize",
      //   icon: "mdi-account-arrow-up-outline",
      // },
      // {
      //   title: "",
      //   to: "Admin Student Categorize",
      //   icon: "mdi-account-arrow-up-outline",
      // },
      
    ]
  })
}
</script>